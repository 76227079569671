
import axios from "axios";
import {useEffect, useState} from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from "@mui/material/Typography";
import {Button, CardActions, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, Modal} from "@mui/material";
import Box from "@mui/material/Box";
import Dates from "../common/dates";
import moment from 'moment';
import _ from "lodash";
const dict = {};
const MADict = {};



var today = moment().format("YYYY-MM-DD");
console.log(today)
var lastTradingDate = "";
for(var i = Dates.length-1; i>=0; i--){
    if(Dates[i] <= today){
        lastTradingDate = Dates[i];
        break;
    }
}
console.log("lastTradingDate", lastTradingDate);
var lastDayBeforeLastTradingDate = "";
for(var i = Dates.length-1; i>=0; i--){
    if(Dates[i] < lastTradingDate){
        lastDayBeforeLastTradingDate = Dates[i];
        break;
    }
}

export default function(props){
    console.log(props)
    const [stocksQueue, setStocksQueue] = useState([]);
    const [filteredStocks, setFilteredStocks] = useState([]);
    const [selectedStock, setSelectedStock] = useState(null);
    const [cates, setCates] = useState([]);

    const fetchStocksQueue = async ()=>{
        const maUrl = 'https://kesyn-1302292689.cos.ap-beijing.myqcloud.com/indicators/ma.txt'
        var maRes = await axios.get(maUrl);
        for(var line of maRes.data.split('\n')){
            if(line.indexOf("nan")>0){
                continue;
            }
            var parts = line.split(',')
            MADict[parts[0]] = Math.max(parts[2], parts[3]);
        }

        const url = `https://kesyn-1302292689.cos.ap-beijing.myqcloud.com/buy/${lastDayBeforeLastTradingDate}.txt`;
        var res = await axios.get(url);
        const indiUrl = "https://stock.deployment.cn/api/indi"
        var indiRes = await axios.get(indiUrl);
        var indiDict = {};
        for(var d of indiRes.data.result){
            indiDict[d.k1d.meta.code] = d;
        }
        // console.log(indiDict)
        var lines = res.data.split('\n');
        var stocks = [];
        for(var line of lines){
            if(line) {
                // console.log(line)
                if(!MADict[line.split(',')[0]]){
                    continue
                }
                if(!indiDict[line.split(',')[0].split("#")[1]]){
                    continue
                }
                // if(line.split(',')[0].split("#")[1].indexOf('3')!=0){
                //     continue;
                // }
                stocks.push({code:line.split(',')[0].split("#")[1], fullCode: line.split(',')[0],  price:line.split(',')[2]});
            }
        }
        var passedStock = [];
        var marketPricesUrl = 'https://tools.ai.deployment.cn/api/public/stock_zh_a_spot_em';
        var marketRes = await axios.get(marketPricesUrl);
        var marketPrices = marketRes.data;
        for(var marketPrice of marketPrices){
            if(indiDict[marketPrice["代码"]]){
                // marketPrice = {...marketPrice, ...indiDict[marketPrice["代码"]]}
                for(var key in indiDict[marketPrice["代码"]]){
                    marketPrice[key] = indiDict[marketPrice["代码"]][key];
                }
            } else{
                marketPrice.strength = 0;
            }
        }
        marketPrices = _.sortBy(marketPrices, c=>-c.strength);
        if(window.location.href.indexOf("strong")>=0){
            marketPrices = _.sortBy(marketPrices, c=>-c.strength * (1+c["5分钟涨跌"]));
        }
        console.log(marketPrices)
        for(var marketPrice of marketPrices){
            if(marketPrice["名称"].indexOf("ST")>=0){
                continue;
            }
            var stock = stocks.find(c=>c.code == marketPrice["代码"]);

            if(stock){
                var maPrice = MADict[stock.fullCode];
                // console.log(maPrice, marketPrice["最新价"]);
                if(marketPrice["最新价"] <= maPrice){
                    continue;
                }
                if(window.location.href.indexOf("kd")>=0) {
                    var kd = marketPrice.k1d.indicators.find(c=>c.name=="KDJ");
                    if(kd){
                        kd = kd.result;
                        if(kd.K[kd.K.length - 1] < kd.D[kd.D.length - 1]){
                            continue;
                        }
                    }
                }
                if(window.location.href.indexOf("boll")>=0) {
                    var boll = marketPrice.k1d.indicators.find(c=>c.name=="BBANDS");
                    if(boll){
                        boll = boll.result;
                        if(boll[0][boll[0].length - 1] <= boll[0][boll[0].length - 2] ){
                            continue;
                        }
                    }
                }
                if(marketPrice["最新价"] >stock.price ){
                    if(window.location.href.indexOf("filter")<0) {
                        passedStock.push(marketPrice["代码"]);
                    }
                    else{
                        if(window.location.href.indexOf("filter=30")>=0){
                            if(marketPrice["代码"].indexOf("3")==0) {
                                passedStock.push(marketPrice["代码"]);
                            }
                        }
                        if(window.location.href.indexOf("hs=true")>=0){
                            if(marketPrice["换手率"]>7 && marketPrice["换手率"]<20) {
                                console.log("hs", marketPrice["换手率"])
                                passedStock.push(marketPrice["代码"]);
                            }
                        }
                    }
                    // marketPrice["代码"] = {...marketPrice["代码"], ...indiDict[stock.code]}
                    for(var key in indiDict[stock.code]){
                        marketPrice[key] = indiDict[stock.code][key];
                    }
                    dict[marketPrice["代码"]] = marketPrice
                    console.log(marketPrice)
                }
            }
        }
        console.log("passed", passedStock)
        var indicesCates = [];
        for(var _stock of passedStock){
            var stock = props.stocks[_stock >= "600000"? _stock + ".SH": _stock + ".SZ"];
            var _indiciesCates = indicesCates;
            var indices = stock.indices.split("-");
            //indices是多层的行业分类，需要将一层分类放入indicesCates，多层分类依次放入每层的subindices中，并在stocks字段里加入stock
            while(indices.length){
                var indicy = indices.shift();
                var cate = _indiciesCates.find(c=>c.name == indicy);
                if(!cate){
                    cate = {name: indicy, subindices: [], stocks: [_stock]};
                    _indiciesCates.push(cate);
                } else {
                    cate.stocks.push(_stock);
                }

                var all = "所有"
                var AllCate = _indiciesCates.find(c=>c.name == all);

                if(!AllCate){
                    AllCate = {name: all, subindices: [], stocks: [_stock]};
                    _indiciesCates.push(AllCate);
                    AllCate.selected = true;
                } else {
                    AllCate.stocks.push(_stock);
                }


                _indiciesCates = cate.subindices;
            }
        }
        //排序所有分类和子分类，让“所有”在第一位，然后按照stocks的数量排序
        function sort(cates){
            for(var cate of cates){
                cate.subindices = sort(cate.subindices);
            }
            return _.sortBy(cates, c=>c.name=="所有"?0: 100-c.stocks.length);
        }
        indicesCates = sort(indicesCates);
        setCates(indicesCates);
        console.log(indicesCates)
        setStocksQueue(passedStock);
    };
    useEffect(()=>{
        setFilteredStocks(stocksQueue);
    }, [stocksQueue])
    useEffect(() => {
        fetchStocksQueue().then();
        // setInterval(function(){
        //     fetchStocksQueue().then();
        // }, 10000)
    }, []);
    const renderPattern = (patterns)=>{
        return <div style={{color: 'white', width: '100%', display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingRight: 10, height: 30, lineHeight: '30px'}}>
            {patterns.map((pattern, i)=>{
                return <div key={i}>
                    <div style={{fontSize: 12}}>
                        {pattern.name}
                    </div>
                    {/*<div  style={{fontSize: 12}}>*/}
                    {/*    {pattern.description}*/}
                    {/*</div>*/}
                </div>
            })}
        </div>
    }
    const resetDefaultSelected = (cate)=>{
        for(var c of cate.subindices){
            c.selected = false;
            resetDefaultSelected(c);
        }
        var all = cate.subindices.find(c=>c.name=="所有");
        if(all){
            all.selected = true;
        }
    }
    const renderCates = (_cates)=>{
        const selectedCate = _cates.find(c=>c.selected);
        return <><div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', cursor: 'pointer', marginBottom: 15}}>{
            _cates.map((cate)=>{
                return <div key={cate.name} style={{marginRight: 10}} onClick={()=>{
                    for(var c of _cates){
                        c.selected = false;
                    }
                    cate.selected = true;
                    resetDefaultSelected(cate);
                    setCates([...cates]);
                    setFilteredStocks(cate.stocks);
                }}>
                    <div style={{fontSize: "15px", color: cate.selected?'#90caf9':'white'}}>{cate.name}</div>
                    {/*{renderCates(cate.subindices)}*/}
                </div>
            })
        }</div>
            {selectedCate&&renderCates(selectedCate.subindices)}
        </>
    }
    //`https://image.sinajs.cn/newchart/daily/n/${code >= "600000"? "sh" + code: "sz" + code }.gif`
    return <>
        <div>
            {renderCates(cates)}
        </div>
        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
        {filteredStocks.map((code) => {
            const stock = props.stocks[code >= "600000"? code + ".SH": code + ".SZ"];
            stock.code = code;
            return <Card sx={{ width: 400 }} style={{marginRight:10, marginBottom: 10}}  key={code}>
                <CardContent key={code}>
                    {/*<img src={`https://image.sinajs.cn/newchart/daily/n/${code >= "600000"? "sh" + code: "sz" + code }.gif`} style={{backgroundColor: 'white', width: '100%'}}/>*/}
                    <div style={{fontSize: '20px'}}>
                        {stock.name}
                    </div>
                    <div style={{fontSize: 15, color: 'red'}}>
                        {dict[code]["涨跌幅"]}% &nbsp;&nbsp; 强度:{dict[code].strength.toFixed(1)} &nbsp;&nbsp; 换手率:{dict[code]["换手率"]}%
                    </div>
                    <div style={{fontSize: 12, color: 'rgba(255,255,255,0.5)'}}>
                        {code}
                    </div>
                    <div style={{color: '#90caf9', fontSize: 12}}>
                        {stock.indices}
                    </div>
                </CardContent>
                <CardMedia
                    component="img"
                    alt="green iguana"
                    width="100%"
                    style={{backgroundColor:'white'}}
                    image={`https://stocks_imgs.middlewares.online/GetPic.aspx?nid=${stock.code>='600000'?1:0}.${stock.code}&type=&unitWidth=-6&ef=EXTENDED_BOLL&formula=KDJ&AT=1&imageType=KXL&timespan=${Math.floor(Date.now()/1000)}`}
                    onClick={()=>{
                        setSelectedStock(stock);
                    }}
                />
                {renderPattern(dict[code].k1d.patterns)}
                <CardMedia
                    component="img"
                    alt="green iguana"
                    width="100%"
                    style={{backgroundColor:'white'}}
                    image={`https://stocks_imgs.middlewares.online/GetPic.aspx?nid=${stock.code>='600000'?1:0}.${stock.code}&type=M60&unitWidth=-6&ef=EXTENDED_BOLL&formula=KDJ&AT=1&imageType=KXL&timespan=${Math.floor(Date.now()/1000)}`}
                    onClick={()=>{
                        setSelectedStock(stock);
                    }}
                />
                {renderPattern(dict[code].k60.patterns)}

                {/*<CardActions>*/}
                {/*    <Button size="small">待选</Button>*/}
                {/*    <Button size="small" color="error">隐藏</Button>*/}
                {/*    <Button size="small" variant="outlined">立即下单</Button>*/}
                {/*</CardActions>*/}
            </Card>
        })}
    </div>

        {selectedStock?<Dialog
            open={selectedStock !== null}
            onClose={()=>{setSelectedStock(null)}}
            fullWidth={true}
            maxWidth={"lg"}
        >
            <DialogTitle style={{backgroundColor: 'black'}}>{selectedStock.name}</DialogTitle>
            <DialogContent style={{backgroundColor: 'black'}}>
                {selectedStock&&<iframe style={{width: '100%', height: 500}}
                    src={`https://quote.eastmoney.com/basic/h5chart-iframe.html?code=${selectedStock.code}&market=${selectedStock.code>='600000'?1:2}&type=r`}></iframe>}
            </DialogContent>
            <DialogActions style={{backgroundColor: 'black'}}>
                <Button size="small">待选</Button>
                <Button size="small" color="error">隐藏</Button>
                <Button size="small" variant="outlined">立即下单</Button>
            </DialogActions>
        </Dialog>:null}
    </>
}
